<template>
  <div class="contents-section">
    <div class="title-section">
      <h2 class="d-flex align-center">
        매출/환불관리
        <v-tooltip
          right
          color="black"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              class="mt-1 ml-1"
              v-bind="attrs"
              v-on="on"
              @click="getSummary()"
              :loading="loading"
              color="primary"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>새로고침</span>
        </v-tooltip>
      </h2>
      <div>
        <v-btn
          v-if="checkFuncPermission('registSales')"
          depressed
          color="success"
          @click="registDlg.type = 0, registDlg.show = true"
        >
          매출등록
        </v-btn>
        <v-btn
          v-if="checkFuncPermission('registRefund')"
          class="ml-2"
          depressed
          color="error"
          @click="registDlg.type = 1, registDlg.show = true"
        >
          환불등록
        </v-btn>
      </div>
    </div>
    <div class="main-section">
      <search-filter
        :dateFilter="dateFilter"
        :radioFilters="radioFilters"
        :selectFilters="selectFilters"
        :search="search"
        type="server"
        searchPlaceHolder="고객정보/영업자/상품명/번호"
        @updateSearch="updateSearch"
        @updateRadio="updateRadio"
        @updateSelect="updateSelect"
      />
      <v-row
        justify="space-between"
        align="center"
        class="mr-0 ml-0 mt-0 mb-0"
      >
        <v-col class="d-flex align-center pl-0">
          <v-tooltip
            top
            color="black"
          >
            <template #activator="{ on: tooltipOn }">
              <v-chip
                class="mt-4 mr-2 pr-1 pl-1 flex-shrink-0"
                label
                outlined
                elevation
                link
                color="success"
                v-on="tooltipOn"
                @click="excelDownloadFunc()"
                :disabled="excelDownloadList === null"
              >
                <v-icon>mdi-microsoft-excel</v-icon>
              </v-chip>
            </template>
            <span>매출목록 다운로드</span>
          </v-tooltip>

          <v-radio-group
            @change="options.itemsPerPage = $event"
            dense
            row
            :value="options.itemsPerPage"
            hide-details
          >
            <v-radio
              small
              label="50개씩 보기"
              :value="50"
            ></v-radio>
            <v-radio
              small
              label="100개씩 보기"
              :value="100"
            ></v-radio>
            <v-radio
              small
              label="500개씩 보기"
              :value="500"
            ></v-radio>
            <v-radio
              small
              label="1000개씩 보기"
              :value="1000"
            ></v-radio>
            <v-radio
              small
              label="3000개씩 보기"
              :value="3000"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col class="d-flex total">
          <p>
            <v-chip
              color="success"
              small
              class="mr-2"
              outlined
            >매출</v-chip>
            {{ comm.priceToString(totalAmount.sales) }}
          </p>
          <p>
            <v-chip
              color="error"
              small
              class="mr-2"
              outlined
            >환불</v-chip>
            {{ comm.priceToString(totalAmount.refund) }}
          </p>
          <p>
            <v-chip
              color="primary"
              small
              class="mr-2"
              outlined
            >TOTAL</v-chip>
            {{ comm.priceToString(totalAmount.total) }}
          </p>
        </v-col>
      </v-row>
      <data-table
        :items="lists"
        :sorts.sync="sorts"
        :headers="headers"
        itemKey="no"
        :loading="loading"
      >
        <template #no="{ item }">
          <div
            class="d-flex flex-wrap"
            style="line-break: anywhere"
          >
            {{ item.no }}
          </div>
        </template>
        <template #type="{ item }">
          <v-tooltip
            top
            color="black"
          >
            <template #activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                class="d-flex"
                @click="contentsView(item.no)"
              >
                <v-chip
                  label
                  color="error"
                  small
                  link
                  class="pr-2 pl-2"
                  v-if="item.type === 1"
                >환불</v-chip>
                <v-chip
                  label
                  color="success"
                  small
                  link
                  class="pr-2 pl-2"
                  @click="contentsView(item.no)"
                  v-else-if="item.type === 0"
                >매출</v-chip>
                <v-chip
                  label
                  color="secondary"
                  outlined
                  small
                  link
                  class="ml-1 pr-1 pl-1"
                  v-if="item.method === 1"
                >카드</v-chip>
                <v-chip
                  label
                  color="accent"
                  outlined
                  small
                  link
                  class="ml-1 pr-1 pl-1"
                  v-else-if="item.method === 0"
                >현금</v-chip>
              </div>
            </template>
            <span>열람</span>
          </v-tooltip>
        </template>
        <template #customer="{ item }">
          <v-tooltip
            top
            color="black"
          >
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >
                {{`
                  ${item.customerName}
                  (${item.customerPhone.slice(-4)})
                `}}
              </span>
            </template>
            <span>{{ comm.makePhoneNumber(item.customerPhone) }}</span>
          </v-tooltip>
        </template>
        <template #manager="{ item }">
          <v-menu
            offset-y
            :close-on-content-click="false"
          >
            <template #activator="{ on, attrs }">
              <div class="d-flex align-center justify-left">
                <v-tooltip
                  top
                  color="black"
                >
                  <template #activator="{ on, attrs }">
                    <div
                      class="d-flex align-center cursor-pointer flex-wrap"
                      v-on="on"
                      v-bind="attrs"
                      @click="getHistories(
                        'sales.update',
                        'manager',
                        '영업담당자 변경이력',
                        item,
                      )"
                    >
                      <v-chip
                        link
                        style="height: auto; white-space: pre-wrap;"
                        class="pl-1 pr-1 mr-1"
                        small
                        label
                        outlined
                      >{{ comm.getGroupFullPath(groups, item.managerGroupNo, '그룹없음') }}</v-chip>
                      {{ item.managerName }}
                    </div>
                  </template>
                  <span>영업담당자 변경이력 열람</span>
                </v-tooltip>
                <v-btn
                  v-if="
                    item.status !== 1
                    && checkFuncPermission('manager')
                  "
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="getManagerList();"
                >
                  <v-icon
                    small
                    color="primary"
                  >mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>
            <v-card class="search-picker">
              <v-text-field
                :autofocus="true"
                dense
                flat
                hide-details
                v-model="managerText"
                placeholder="영업담당자 검색"
                @input="managerFiltering"
                color="black"
                outlined
                solo
              ></v-text-field>
              <v-list v-if="managerFilter.length > 0">
                <v-list-item
                  v-for="(row, i) in managerFilter"
                  :key="i"
                  dense
                  nav
                  link
                  @click="updateManager(item.no, row)"
                >
                  <v-list-item-title>
                    <v-chip
                      small
                      class="pr-1 pl-1 mr-1"
                      link
                      outlined
                      label
                    >
                      {{ row.group }}
                    </v-chip>
                    {{ row.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
              <v-list v-else-if="managerFilter.length === 0">
                <v-list-item dense>
                  <v-list-item-title>{{
                    managerText == null
                    || managerText.trim().length === 0
                    ?'검색어를 입력해주세요.'
                    :'데이터가 없습니다.'
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </template>
        <template #groupNo="{ item }">
          <v-menu
            offset-y
            :close-on-content-click="false"
          >
            <template #activator="{ on, attrs }">
              <div class="d-flex align-center">
                <v-tooltip
                  top
                  color="black"
                >
                  <template #activator="{ on, attrs }">
                    <div
                      class="d-flex align-center cursor-pointer"
                      v-on="on"
                      v-bind="attrs"
                      @click="getHistories(
                        'sales.update',
                        'group',
                        '영업그룹 변경이력',
                        item,
                      )"
                    >
                      <v-chip
                        style="height: auto; white-space: pre-wrap;"
                        link
                        class="pl-1 pr-1"
                        small
                        label
                        outlined
                      >{{ comm.getGroupFullPath(groups, item.group, '그룹없음') }}</v-chip>
                    </div>
                  </template>
                  <span>영업그룹 변경이력 열람</span>
                </v-tooltip>
                <v-btn
                  v-if="
                    item.status !== 1
                    && checkFuncPermission('manager')
                  "
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="getGroupList();"
                >
                  <v-icon
                    small
                    color="primary"
                  >mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>
            <v-card class="search-picker">
              <v-text-field
                :autofocus="true"
                dense
                flat
                hide-details
                v-model="groupText"
                placeholder="영업그룹 검색"
                @input="groupFiltering"
                color="black"
                outlined
                solo
              ></v-text-field>
              <v-list v-if="groupFilter.length > 0">
                <v-list-item
                  v-for="(row, i) in groupFilter"
                  :key="i"
                  dense
                  nav
                  link
                  @click="updateGroup(item.no, row)"
                >
                  <v-list-item-title>
                    {{ row.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
              <v-list v-else-if="groupFilter.length === 0">
                <v-list-item dense>
                  <v-list-item-title>{{
                    groupText == null
                    || groupText.trim().length === 0
                    ?'검색어를 입력해주세요.'
                    :'데이터가 없습니다.'
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </template>
        <template #productNo="{ item }">
          <div
            v-if="item.productType === 0"
            class="d-flex align-center"
          >
            <span class="d-flex align-center">
              <v-chip
                x-small
                class="pl-1 pr-1 mr-1"
                color="info"
                label
              >리딩</v-chip>
              {{ item.productName }}
            </span>
            <v-tooltip
              top
              color="black"
              v-if="item.type === 0"
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  small
                  color="primary"
                  v-on="on"
                  @mouseenter="getProductRooms(item.productNo)"
                >
                  <v-icon size="18px">mdi-chat-question</v-icon>
                </v-btn>
              </template>
              <span v-if="currentRooms === null">
                <span>
                  이용기간: {{ item.productTerm }} 개월<br />
                  무료이용기간: {{ item.productFreeTerm }} 개월
                </span>
              </span>
              <span v-else-if="currentRooms.length > 0">
                <span>
                  이용기간: {{ item.productTerm }} 개월<br />
                  무료이용기간: {{ item.productFreeTerm }} 개월<br /><br />
                </span>
                <span
                  v-for="room in currentRooms"
                  :key="room.no"
                >
                  {{ room.name }}
                  <br />
                </span>
              </span>
              <span v-else>
                <span>
                  이용기간: {{ item.productTerm }} 개월<br />
                  무료이용기간: {{ item.productFreeTerm }} 개월<br /><br />
                </span>
                상품에 적용된 리딩방이 없습니다.
              </span>
            </v-tooltip>
          </div>
          <span
            class="d-flex align-center"
            v-else
          >
            <v-chip
              x-small
              class="pl-1 pr-1 mr-1"
              color="warning"
              label
            >코인</v-chip>
            {{ item.productName }}
          </span>
        </template>
        <template #quantity="{ item }">
          <span
            class="gray--text"
            v-if="item.productType === 0"
          >
            -
          </span>
          <span
            v-else
            style="line-break: anywhere"
          >{{ item.quantity }}</span>
        </template>
        <template #status="{ item }">
          <v-menu
            offset-y
            v-if="checkFuncPermission('status')"
          >
            <template #activator="{ on, attrs }">
              <v-chip
                v-if="item.status === 0"
                class="pl-1 pr-1"
                small
                label
                v-bind="attrs"
                v-on="on"
              >
                미승인
              </v-chip>
              <v-chip
                v-else-if="item.status === 2"
                class="pl-1 pr-1"
                small
                label
                outlined
                v-bind="attrs"
                v-on="on"
                color="error"
              >
                가결제
              </v-chip>
              <v-chip
                v-else-if="item.status === 1"
                class="pl-1 pr-1"
                small
                label
                outlined
                v-bind="attrs"
                v-on="on"
              >
                {{ time.makeLocalTime(item.date, 'date') }}
              </v-chip>
            </template>
            <!-- 상태변경: 매출 -->
            <v-list v-if="item.type === 0">
              <v-list-item
                class="options"
                @click="confirmDlgOpen(item, 1)"
                v-if="item.status === 0 || item.status === 2"
              >매출 승인</v-list-item>
              <v-list-item
                class="options"
                @click="updateSalesStatus(item, 2)"
                v-if="
                  item.status !== 2
                  && item.productType === 0">가결제 처리</v-list-item>
              <v-list-item
                class="options"
                @click="updateSalesStatus(item, 0)"
                v-if="item.status === 2"
              >미승인으로 변경</v-list-item>
              <v-list-item
                v-if="item.status === 1"
                class="options"
                @click="confirmDlgOpen(item)"
              >매출일 변경</v-list-item>
              <v-list-item
                class="options"
                @click="updateSalesStatus(item, 0)"
                v-if="item.status === 1"
              >승인 취소</v-list-item>
              <v-list-item
                class="options"
                @click="getHistories(
                  'sales.update',
                  'status',
                  '매출승인이력',
                  item,
                )"
              >
                매출승인이력
              </v-list-item>
              <v-list-item
                v-if="item.status === 0"
                class="options error--text"
                @click="updateDisabled(item.no)"
              >
                삭제
              </v-list-item>
            </v-list>
            <!-- 상태변경: 환불 -->
            <v-list v-if="item.type === 1">
              <v-list-item
                class="options"
                @click="confirmDlgOpen(item, 1)"
                v-if="item.status === 0 || item.status === 2"
              >환불 승인</v-list-item>
              <v-list-item
                class="options"
                @click="updateRefundStatus(item, 0)"
                v-if="item.status === 2"
              >미승인으로 변경</v-list-item>
              <v-list-item
                v-if="item.status === 1"
                class="options"
                @click="confirmDlgOpen(item)"
              >환불일 변경</v-list-item>
              <v-list-item
                class="options"
                @click="updateRefundStatus(item, 0)"
                v-if="item.status === 1"
              >승인 취소</v-list-item>
              <v-list-item
                class="options"
                @click="getHistories(
                  'sales.update',
                  'status',
                  '환불승인이력',
                  item,
                )"
              >
                환불승인이력
              </v-list-item>
              <v-list-item
                v-if="item.status === 0"
                class="options error--text"
                @click="updateDisabled(item.no)"
              >
                삭제
              </v-list-item>
            </v-list>
          </v-menu>
          <div v-else>
            <v-chip
              v-if="item.status === 0"
              class="pl-1 pr-1"
              small
              label
            >
              미승인
            </v-chip>
            <v-chip
              v-else-if="item.status === 2"
              class="pl-1 pr-1"
              small
              label
              outlined
              color="error"
            >
              가결제
            </v-chip>
            <v-chip
              v-else-if="item.status === 1"
              class="pl-1 pr-1"
              small
              label
              outlined
            >
              {{ time.makeLocalTime(item.date, 'date') }}
            </v-chip>
          </div>
        </template>
        <template #paymentsDiff="{ item }">
          <v-tooltip
            top
            color="black"
          >
            <template #activator="{ on, attrs }">
              <div v-if="item.type === 0">
                <span
                  v-if="getPaymentsDiff(item) > 0"
                  class="success--text"
                  v-on="on"
                  v-bind="attrs"
                >
                  +{{ comm.priceToString(getPaymentsDiff(item)) }}
                </span>
                <span
                  v-else-if="getPaymentsDiff(item) < 0"
                  class="error--text"
                  v-on="on"
                  v-bind="attrs"
                >
                  {{ comm.priceToString(getPaymentsDiff(item) * -1) }}
                </span>
                <span
                  v-on="on"
                  v-bind="attrs"
                  v-else
                >
                  0 원
                </span>
              </div>
              <span
                v-else-if="item.type === 1"
                class="gray--text"
              >
                -
              </span>
            </template>
            <span>
              판매가: {{
                comm.priceToString((item.productPrice * item.quantity) + item.discount)
              }}<br />
              결제총액: {{ comm.priceToString(item.amount) }}<br />
            </span>
          </v-tooltip>

        </template>
        <template #amount="{ item }">
          <span
            v-if="item.amount === 0 || item.amount === null"
            style="text-align: right"
          >
            {{ comm.priceToString(item.amount) }}
          </span>
          <b
            v-else-if="item.amount > 0"
            class="success--text"
            style="text-align: right"
          >
            {{ comm.priceToString(item.amount) }}
          </b>
          <b
            v-else-if="item.amount < 0"
            class="error--text"
            style="text-align: right"
          >
            {{ comm.priceToString(item.amount) }}
          </b>
        </template>
      </data-table>
      <div class="text-center pt-4 d-flex justify-center align-center">
        <v-btn
          icon
          v-if="Math.ceil(totalItems / options.itemsPerPage) > 1"
          @click="options.page = 1"
          :disabled="options.page === 1"
        >
          <v-icon>mdi-chevron-double-left</v-icon>
        </v-btn>
        <v-pagination
          v-if="options.itemsPerPage > 0"
          small
          color="primary"
          v-model="options.page"
          :length="Math.ceil(totalItems / options.itemsPerPage)"
          totalVisible="15"
          depressed
        ></v-pagination>
        <v-btn
          icon
          v-if="Math.ceil(totalItems / options.itemsPerPage) > 1"
          @click="options.page = Math.ceil(totalItems / options.itemsPerPage)"
          :disabled="options.page === Math.ceil(totalItems / options.itemsPerPage)"
        >
          <v-icon>mdi-chevron-double-right</v-icon>
        </v-btn>
      </div>
    </div>
    <dlg-sales-regist
      :show.sync="registDlg.show"
      :type="registDlg.type"
      :refresh="getSummary"
    />
    <v-dialog
      v-model="confirmDlg.show"
      width="500px"
    >
      <div
        class="date-picker-top"
        v-if="currentItem !== null"
      >
        <p class="tit">
          <span v-if="currentItem.status === 1">
            {{ currentItem.type === 0 ? '매출' : '환불' }}일 변경
          </span>
          <span v-else>
            {{ currentItem.type === 0 ? '매출' : '환불' }} 승인
          </span>
        </p>
        <v-chip
          small
          class="ml-2"
        >
          {{ currentItem.type === 0 ? '매출' : '환불' }}번호: {{ currentItem.no }}
        </v-chip>
      </div>

      <!-- 매출인 경우 -->
      <div
        class="confirm-picker-body"
        v-if="currentItem !== null && currentItem.type === 0"
      >
        <!-- 매출승인인 경우만 -->
        <div v-if="confirmDlg.toStatus === 1">
          <div class="section">
            <p class="tit">
              판매가
            </p>
            <div class="sub ml-2">
              {{
                comm.priceToString(
                  (currentItem.productPrice * currentItem.quantity) + currentItem.discount
                )
              }}
            </div>
          </div>
          <div class="section">
            <p class="tit">
              결제총액
            </p>
            <div class="sub ml-2">
              {{ comm.priceToString(currentItem.amount) }}
            </div>
          </div>
          <div class="section">
            <p class="tit">
              결제차액
            </p>
            <div
              class="sub ml-2"
              :class="currentItem.amount - (
                (currentItem.productPrice * currentItem.quantity) + currentItem.discount
              ) < 0 ? 'error--text' : ''"
            >
              {{
                comm.priceToString(
                  currentItem.amount - (
                    (currentItem.productPrice * currentItem.quantity) + currentItem.discount
                  )
                )
              }}
            </div>
          </div>
          <div class="section">
            <p class="tit">
              매출합계
            </p>
            <div class="sub ml-2">
              {{ comm.priceToString(currentItem.amount) }}
            </div>
          </div>
        </div>

        <div class="section">
          <p class="tit">
            매출승인일
            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  class="question"
                  small
                  color="warning"
                  v-on="on"
                  v-bind="attrs"
                >mdi-help-circle-outline</v-icon>
              </template>
              <span>
                영업담당자의 인센티브 정산이 적용되는 날짜입니다.<br />
              </span>
            </v-tooltip>
          </p>
          <div class="sub ml-2">
            {{ time.makeLocalDate(confirmDate, 'date') }}
            <v-menu
              :close-on-content-click="true"
              :nudge-left="90"
              origin="right top"
              transition="scale-transition"
              absolute
              offset-overflow
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="primary"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon size="18px">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <v-date-picker v-model="confirmDate"></v-date-picker>
            </v-menu>
          </div>
        </div>

        <!-- 매출승인이고 리딩매출인 경우만 -->
        <div
          class="section"
          v-if="
            confirmDlg.toStatus === 1
            && currentItem.productType === 0
          "
        >
          <p class="tit">
            서비스이용기간
            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  class="question"
                  small
                  color="warning"
                  v-on="on"
                  v-bind="attrs"
                >mdi-help-circle-outline</v-icon>
              </template>
              <span>
                해당 기간동안 서비스가 제공됩니다.<br />
                즉시 제공을 원하시면 오늘 날짜({{ time.moment().format('YYYY-MM-DD') }})로 지정해주세요.
              </span>
            </v-tooltip>
          </p>
          <div class="sub ml-2">
            {{ serviceStartDate }}
            <v-menu
              :close-on-content-click="true"
              :nudge-left="90"
              origin="right top"
              transition="scale-transition"
              absolute
              offset-overflow
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="primary"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon size="18px">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <v-date-picker
                v-model="serviceStartDate"
                @input="setServiceEndDate"
                :min="time.moment().format('YYYY-MM-DD')"
              ></v-date-picker>
            </v-menu>
            ~
            <span class="ml-2">{{ serviceEndDate }}</span>
            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  class="question ml-1"
                  small
                  color="warning"
                  v-on="on"
                  v-bind="attrs"
                >mdi-help-circle-outline</v-icon>
              </template>
              <span>
                종료일은 시작일을 기점으로 자동계산되어 적용됩니다.<br />
                시작일 + 상품이용기간({{
                  currentItem.productTerm + currentItem.productFreeTerm
                }}개월)
              </span>
            </v-tooltip>
          </div>
        </div>
        <div class="section justify-end pb-0 pr-0">
          <v-btn
            elevation
            color="primary"
            v-if="confirmDlg.toStatus === 1"
            @click="updateSalesStatus(currentItem, 1)"
          >
            매출 승인
          </v-btn>
          <v-btn
            elevation
            color="primary"
            @click="updateDate(currentItem)"
            v-else
          >
            매출일 변경
          </v-btn>
        </div>
      </div>

      <!-- 환불인 경우 -->
      <div
        class="confirm-picker-body"
        v-else-if="
          currentItem !== null && currentItem.type === 1 && currentItem.originSale !== undefined
        "
      >
        <!-- 환불승인인 경우만 -->
        <div v-if="confirmDlg.toStatus === 1">
          <div class="section">
            <p class="tit">
              기존매출액
            </p>
            <div class="sub ml-2">
              {{ comm.priceToString(currentItem.originSale.amount) }}
            </div>
          </div>
          <div
            class="section"
            v-if="currentItem.originSale.serviceStart !== null"
          >
            <p class="tit">
              서비스시작일
            </p>
            <div class="sub ml-2">
              {{ getEstRefundAmount(confirmDate).saleDate }}
            </div>
          </div>
          <div class="section">
            <p class="tit">
              환불승인일
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    class="question"
                    small
                    color="warning"
                    v-on="on"
                    v-bind="attrs"
                  >mdi-help-circle-outline</v-icon>
                </template>
                <span>
                  영업담당자의 인센티브 정산이 적용되는 날짜입니다.<br />
                  ※ 고객의 서비스는 환불승인일과 별개로 즉시 중단됩니다.
                </span>
              </v-tooltip>
            </p>
            <div class="sub ml-2">
              {{ time.makeLocalDate(confirmDate, 'date') }}
              <v-menu
                :close-on-content-click="true"
                :nudge-left="90"
                origin="right top"
                transition="scale-transition"
                absolute
                offset-overflow
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="primary"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon size="18px">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="confirmDate"></v-date-picker>
              </v-menu>
            </div>
          </div>
          <div
            class="section"
            v-if="currentItem.originSale.serviceStart !== null"
          >
            <p class="tit">
              일할계산합계
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    class="question ml-1"
                    small
                    color="warning"
                    v-on="on"
                    v-bind="attrs"
                  >mdi-help-circle-outline</v-icon>
                </template>
                <span>
                  기존매출정보와 환불승인일을 기준으로 일할계산된 금액입니다.<br />
                  ※ 실제 환불액은 계좌환불/카드취소건의 합으로 집계됩니다.
                </span>
              </v-tooltip>
            </p>
            <div class="sub ml-2">
              {{ comm.priceToString(getEstRefundAmount(confirmDate).refundPrice) }}
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    class="question ml-1"
                    small
                    color="warning"
                    v-on="on"
                    v-bind="attrs"
                  >mdi-help-circle-outline</v-icon>
                </template>
                <span>
                  서비스시작일: {{ getEstRefundAmount(confirmDate).saleDate }}<br />
                  일할계산종료일: {{ getEstRefundAmount(confirmDate).endDate }}<br />
                  환불일: {{ getEstRefundAmount(confirmDate).date }}<br />
                  이용일수: {{ getEstRefundAmount(confirmDate).diffDays }}일<br />
                  일할계산잔여일수: {{ getEstRefundAmount(confirmDate).remainDays }}일<br />
                  일할계산(1일): {{
                    comm.priceToString(getEstRefundAmount(confirmDate).dayPrice)
                  }}<br />
                  일할계산(합계): {{
                    comm.priceToString(getEstRefundAmount(confirmDate).refundPrice)
                  }}<br />
                </span>
              </v-tooltip>
            </div>
          </div>
          <div class="section">
            <p class="tit">
              환불총액
            </p>
            <div class="sub ml-2">
              {{ comm.priceToString(currentItem.amount * -1) }}
            </div>
          </div>
        </div>
        <div v-else>
          <div class="section">
            <p class="tit">
              환불승인일
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    class="question"
                    small
                    color="warning"
                    v-on="on"
                    v-bind="attrs"
                  >mdi-help-circle-outline</v-icon>
                </template>
                <span>
                  영업담당자의 인센티브 정산이 적용되는 날짜입니다.<br />
                </span>
              </v-tooltip>
            </p>
            <div class="sub ml-2">
              {{ time.makeLocalDate(confirmDate, 'date') }}
              <v-menu
                :close-on-content-click="true"
                :nudge-left="90"
                origin="right top"
                transition="scale-transition"
                absolute
                offset-overflow
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="primary"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon size="18px">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="confirmDate"></v-date-picker>
              </v-menu>
            </div>
          </div>
        </div>
        <div class="section justify-end pb-0 pr-0">
          <v-btn
            elevation
            color="primary"
            v-if="confirmDlg.toStatus === 1"
            @click="updateRefundStatus(currentItem, 1)"
          >
            환불 승인
          </v-btn>
          <v-btn
            elevation
            color="primary"
            @click="updateDate(currentItem)"
            v-else
          >
            환불일 변경
          </v-btn>
        </div>
      </div>
    </v-dialog>
    <dlg-history
      :show.sync="historyDlg.show"
      :data="historyDlg.data"
      :title="historyDlg.title"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import time from '@/util/time';
import comm from '@/util/comm';
import SearchFilter from '@/components/layout/SearchFilter.vue';
import DlgSalesRegist from '@/components/dialog/DlgSalesRegist.vue';
import DataTable from '@/components/datatable/DataTable.vue';
import DlgHistory from '@/components/dialog/DlgHistory.vue';
import debounce from 'debounce';

export default {
  name: 'Sales',
  components: {
    SearchFilter,
    DlgSalesRegist,
    DlgHistory,
    DataTable,
  },
  data: () => ({
    comm,
    time,
    productName: '',
    registDlg: {
      type: 0,
      show: false,
    },
    confirmDate: time.moment().format('YYYY-MM-DD'),
    serviceStartDate: time.moment().format('YYYY-MM-DD'),
    serviceEndDate: time.moment().format('YYYY-MM-DD'),
    confirmDlg: {
      show: false,
      toStatus: 0,
    },
    currentItem: null,
    groups: [],
    loading: true,
    dateFilter: {
      label: '매출/환불일',
      startDate: time.moment().add('-1', 'Y').format('YYYY-MM-DD'),
      startPicker: false,
      endDate: time.moment().format('YYYY-MM-DD'),
      endPicker: false,
    },
    lists: [],
    search: '',
    totalItems: 0,
    radioFilters: {
      type: {
        key: 'type',
        name: '종류',
        value: '전체',
        data: [
          { name: '전체', value: '전체' },
          { name: '매출', value: 0 },
          { name: '환불', value: 1 },
        ],
      },
      productType: {
        key: 'productType',
        name: '상품',
        value: '전체',
        data: [
          { name: '전체', value: '전체' },
          { name: '리딩', value: 0 },
          { name: '코인', value: 1 },
        ],
      },
      status: {
        key: 'status',
        name: '상태',
        value: '전체',
        data: [
          { name: '전체', value: '전체' },
          { name: '미승인', value: 0 },
          { name: '승인', value: 1 },
          { name: '가결제', value: 2 },
        ],
      },
      method: {
        key: 'method',
        name: '결제',
        value: '전체',
        data: [
          { name: '전체', value: '전체' },
          { name: '현금', value: 0 },
          { name: '카드', value: 1 },
        ],
      },
    },
    selectFilters: {
      groupNo: {
        key: 'groupNo',
        name: '그룹',
        value: '전체',
        data: [],
      },
    },
    options: {
      page: 1,
      itemsPerPage: 50,
      filters: [
        {
          filters: [
            {
              filters: [
                { condition: 'ge', column: 'date', value: time.makeUTCDate(time.moment().add('-1', 'Y').format('YYYY-MM-DD')) },
                {
                  where: 'and',
                  condition: 'le',
                  column: 'date',
                  value: `
                    ${time.makeUTCDate(time.moment().format('YYYY-MM-DD'))} 23:59:59
                  `,
                },
              ],
            },
            {
              where: 'or',
              condition: 'none',
              column: 'date',
            },
          ],
        },
        {
          where: 'and',
          condition: 'inc',
          column: 'type',
          value: '',
        },
        {
          where: 'and',
          condition: 'inc',
          column: 'productType',
          value: '',
        },
        {
          where: 'and',
          condition: 'inc',
          column: 'status',
          value: '',
        },
        {
          where: 'and',
          condition: 'inc',
          column: 'method',
          value: '',
        },
        {
          where: 'and',
          condition: 'inc',
          column: 'discount', // groupNo = null 을 조회하기위해 검색하지않는 컬럼을 기본지정함
          value: '',
        },
        {
          where: 'and',
          filters: [
            {
              condition: 'inc',
              column: 'customerName',
              value: '',
            },
            {
              where: 'or',
              condition: 'inc',
              column: 'managerName',
              value: '',
            },
            {
              where: 'or',
              condition: 'inc',
              column: 'customerPhone',
              value: '',
            },
            {
              where: 'or',
              condition: 'inc',
              column: 'productName',
              value: '',
            },
            {
              where: 'or',
              condition: 'inc',
              column: 'no',
              value: '',
            },
          ],
        },
      ],
    },
    managerText: null,
    managers: [],
    managerFilter: [],
    groupText: null,
    groupFilter: [],
    groupList: [],
    totalAmount: { refund: 0, sales: 0, total: 0 },
    sorts: {
      sortBy: ['no'],
      sortDesc: [true],
    },
    headers: [
      {
        text: '번호',
        value: 'no',
        align: 'center',
        width: 54,
        sortable: false,
      },
      {
        text: '종류', // 매출 or 환불
        value: 'type',
        align: 'center',
        sortable: false,
        width: 100,
      },
      {
        text: '상태(승인일)', // 상태
        value: 'status',
        align: 'left',
        sortable: false,
        width: 90,
      },
      {
        text: '고객정보',
        value: 'customer',
        align: 'left',
        sortable: false,
        width: 150,
      },
      {
        text: '상품',
        value: 'productNo',
        align: 'left',
        width: 200,
      },
      {
        text: '수량',
        value: 'quantity',
        align: 'center',
        width: 64,
      },
      {
        text: '결제차액',
        value: 'paymentsDiff',
        align: 'right',
        sortable: false,
        width: 120,
      },
      {
        text: '합계',
        value: 'amount',
        align: 'right',
      },
      {
        text: '영업담당자',
        value: 'manager',
        align: 'left',
        width: 260,
      },
      {
        text: '영업그룹',
        value: 'groupNo',
        align: 'left',
        width: 200,
      },
    ],
    historyDlg: {
      show: false,
      data: [],
      title: '',
      sub: null,
    },
    makeHistories: {
      status: (data, vue) => {
        const result = [];
        const thisType = vue.currentItem.type;
        const msgObj = {
          0: '미승인',
          1: '승인',
          2: '가결제',
          3: thisType === 1 ? '환불일 변경' : '매출일 변경',
        };
        data.forEach((r) => {
          if (r.after.status !== undefined || r.after.date !== undefined) {
            const msgKey = r.after.status !== undefined ? r.after.status : 3;
            let msg = msgObj[String(msgKey)];
            if (r.after.date !== undefined) {
              msg += `[${time.makeLocalDate(r.after.date, 'date')}]`;
            }
            result.push({ ...r, after: msg });
          }
        });
        return result;
      },
      manager: (data, staffs) => {
        const result = [];
        data.forEach((r) => {
          if (r.after.manager !== undefined) {
            const { fullName } = staffs.find((row) => r.after.manager === row.id);
            result.push({ ...r, after: `${fullName}` });
          }
        });
        return result;
      },
      group: (data, vue) => {
        const result = [];
        data.forEach((r) => {
          if (r.after.groupNo !== undefined) {
            result.push({ ...r, after: `${comm.getGroupFullPath(vue.groups, r.after.groupNo)}` });
          } else if (r.after.group !== undefined) {
            result.push({ ...r, after: `${comm.getGroupFullPath(vue.groups, r.after.group)}` });
          }
        });
        return result;
      },
    },
    excelDownloadList: [],
    currentRooms: null,
  }),
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
      route: 'router/route',
      saleDlg: 'dialog/sale',
    }),
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
      confirm: 'dialog/confirm',
      sale: 'dialog/sale',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    /* 검색 업데이트 */
    updateSearch(value) {
      this.search = value;
    },
    /* 셀렉트필터 업데이트 */
    updateSelect(value, key) {
      this.selectFilters[key].value = value;
    },
    /* 라디오필터 업데이트 */
    updateRadio(value, key) {
      this.radioFilters[key].value = value;
    },
    getSummary() {
      this.loading = true;
      this.$socket.emit('sales.summary.get', {
        filters: this.options.filters,
      }, (res) => {
        if (res.result === 'success') {
          this.$socket.emit('sales.list.get', {
            ...this.options,
            ...this.sorts,
          }, (resp) => {
            if (resp.result === 'success') {
              this.totalItems = res.item.salesCount + res.item.refundCount;
              this.totalAmount = {
                refund: Number(res.item.refundAmount),
                sales: Number(res.item.salesAmount),
                total: Number(res.item.refundAmount) + Number(res.item.salesAmount),
              };
              this.lists = resp.items;
              if (this.options.page > Math.ceil(this.totalItems / this.options.itemsPerPage)) {
                this.options.page = 1;
              }
              this.loading = false;
              this.progress(false);
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
              console.error(resp);
              this.loading = false;
              this.progress(false);
            }
          });
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${res.code})`]);
          console.error(res);
          this.loading = false;
          this.progress(false);
        }
      });
    },
    getDefaultData() {
      this.loading = true;
      const errFunc = (resp) => {
        this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
        console.log(resp);
        this.loading = false;
        this.lists = [];
      };
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: [],
        sortDesc: [],
        filters: [],
      }, (res) => {
        if (res.result === 'success') {
          this.groups = res.items.groups;
          this.selectFilters.groupNo.data = comm.makeGroupList(res.items.groups, 'select');
          this.getSummary();
        } else {
          errFunc(res);
        }
      });
    },
    getGroupList() {
      this.groupText = null;
      this.groupFilter = [];
      this.groupList = [];
      this.progress(true);
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          this.groups = resp.items.groups;
          this.groupList = comm.makeGroupList(resp.items.groups);
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    updateOptions(type, value) {
      this.options[type] = [value];
    },
    contentsView(no) {
      this.progress(true);
      this.$socket.emit('sales.get', { no }, (resp) => {
        if (resp.result === 'success') {
          this.sale({
            show: true,
            item: resp.item,
            func: () => {
              this.getSummary();
            },
          });
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    getManagerList() {
      this.managerText = null;
      this.managers = [];
      this.managerFilter = [];
      this.$socket.emit('users.staffs.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['groupNo'],
        sortDesc: [false],
        filters: [{ condition: 'eq', column: 'is_sales', value: 1 }],
      }, (resp) => {
        if (resp.result === 'success') {
          resp.items.forEach((r) => {
            const groupName = comm.getGroupFullPath(this.groups, r.groupNo, '그룹없음 ');
            const manager = {
              id: r.id,
              fullName: `${groupName}${r.name}`,
              name: r.name,
              group: groupName,
            };
            this.managers.push(manager);
          });
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    managerFiltering(e) {
      if (e != null && e.length > 0) {
        const result = this.managers.filter((r) => r.fullName.indexOf(e) !== -1);
        this.managerFilter = result;
      } else {
        this.managerFilter = [];
      }
    },
    groupFiltering(e) {
      if (e != null && e.length > 0) {
        const result = this.groupList.filter((r) => r.name.indexOf(e) !== -1);
        this.groupFilter = result;
      } else {
        this.groupFilter = [];
      }
    },
    updateDisabled(no) {
      const func = () => {
        this.confirm({ show: false });
        this.$socket.emit('sales.update', { no, disabled: true }, (resp) => {
          if (resp.result === 'success') {
            this.alert(['success', '삭제되었습니다.']);
            this.getSummary();
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
          }
        });
      };
      this.confirm({
        show: true,
        func,
        msg: `삭제된 매출/환불건은 집계에서 제외되며 복구할 수 없습니다.
          <br/><br/>정말 삭제할까요?`,
        btnText: '삭제',
        color: 'error',
      });
    },
    updateManager(no, staff) {
      const func = () => {
        this.updateSales({
          no,
          salesManager: staff.id,
        }, '영업담당자가 변경되었습니다.');
      };
      this.confirm({
        show: true,
        func,
        msg: `영업담당자를 ${staff.fullName}님 으로 변경할까요?<br/><br/>※변경한 영업담당자의 실적으로 집계됩니다.`,
        btnText: '변경',
        color: 'error',
      });
    },
    updateGroup(no, group) {
      const func = () => {
        this.updateSales({
          no,
          groupNo: group.value,
        }, '영업그룹이 변경되었습니다.');
      };
      this.confirm({
        show: true,
        func,
        msg: `영업그룹을 '${group.name}' 그룹으로 변경할까요?<br/><br/>※변경한 영업그룹의 실적으로 집계됩니다.`,
        btnText: '변경',
        color: 'error',
      });
    },
    pickerInit() {
      this.confirm({ show: false });
      this.confirmDate = this.time.moment().format('YYYY-MM-DD');
      this.serviceStartDate = this.time.moment().format('YYYY-MM-DD');
      this.serviceEndDate = `${this.time.moment().format('YYYY-MM-DD')} 23:59:59`;
      this.confirmDlg = {
        show: false,
        toStatus: 0,
      };
      this.currentItem = null;
    },
    updateSalesStatus(item, toStatus) {
      const msgArr = [
        '매출 승인이 취소되었습니다.',
        '매출이 승인되었습니다.',
        '가결제 처리 되었습니다.',
      ];
      if (toStatus === 1) {
        const func = () => {
          this.progress(true);
          this.$socket.emit('rooms.list.get', {
            page: 1,
            itemsPerPage: 0,
            sortBy: ['no'],
            sortDesc: [true],
            filters: [{
              condition: 'eq',
              column: 'disabled',
              value: 0,
            }, {
              where: 'and',
              condition: 'eq',
              column: 'productNo',
              value: item.productNo,
            }],
          }, (resp) => {
            if (resp.result === 'success') {
              this.updateSales({
                no: item.no,
                start: this.serviceStartDate,
                end: this.serviceEndDate,
                status: toStatus,
                date: this.confirmDate,
                rooms: resp.items.map((room) => ({ no: room.no, name: room.name })),
              }, msgArr[toStatus]);
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
              console.error(resp);
              this.progress(false);
            }
          });
        };
        const msg = '매출을 승인할까요?';
        this.confirm({
          show: true,
          msg,
          func,
          btnText: '매출 승인',
          color: 'success',
        });
      // 미승인으로 변경 (승인취소)
      } else if (toStatus === 0) {
        let msg = '고객에게로의 서비스 제공이 중지되며<br/>매출이 영업실적에서 제외됩니다.<br/><br/>정말 승인 취소 할까요?';
        if (item.status === 2 && item.productType === 0) {
          msg = '고객에게로의 서비스 제공이 중지됩니다.<br/><br/>미승인으로 변경할까요?';
        } else if (item.productType === 1) {
          msg = '매출이 영업실적에서 제외됩니다.<br/><br/>정말 승인 취소 할까요?';
        }
        this.confirm({
          show: true,
          msg,
          func: () => {
            this.updateSales({
              no: item.no,
              status: toStatus,
            }, msgArr[toStatus]);
          },
          btnText: item.status === 1 ? '승인 취소' : '미승인으로 변경',
          color: 'error',
        });
      } else if (toStatus === 2) {
        this.progress(true);
        this.$socket.emit('service.count.get', { customer: item.customer }, (resp) => {
          if (resp.result === 'success') {
            if (resp.item[0].serviceCount <= 0) {
              const msg = '가결제 상태인 동안 고객에게 서비스가 제공됩니다.<br/><br/>정말 가결제 처리 할까요?';
              this.confirm({
                show: true,
                msg,
                func: () => {
                  this.$socket.emit('rooms.list.get', {
                    page: 1,
                    itemsPerPage: 0,
                    sortBy: ['no'],
                    sortDesc: [true],
                    filters: [{
                      condition: 'eq',
                      column: 'disabled',
                      value: 0,
                    }, {
                      where: 'and',
                      condition: 'eq',
                      column: 'productNo',
                      value: item.productNo,
                    }],
                  }, (response) => {
                    if (response.result === 'success') {
                      this.updateSales({
                        no: item.no,
                        start: time.moment().format('YYYY-MM-DD'),
                        status: toStatus,
                        rooms: response.items.map((room) => ({ no: room.no, name: room.name })),
                      }, msgArr[toStatus]);
                    } else {
                      this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${response.code})`]);
                      console.error(response);
                      this.progress(false);
                    }
                  });
                },
                btnText: '가결제 처리',
                color: 'error',
              });
            } else {
              this.alert(['error', '고객에게 이미 제공중인 서비스가 있어 처리할 수 없습니다.']);
            }
            this.progress(false);
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
            this.progress(false);
          }
        });
      } else {
        this.alert(['error', '오류가 발생했습니다.']);
      }
    },
    updateRefundStatus(item, toStatus) {
      const msgArr = [
        '환불 승인이 취소되었습니다.',
        '환불이 승인되었습니다.',
      ];
      if (toStatus === 1) {
        const amountDiff = this.getEstRefundAmount(
          this.confirmDate,
        ).refundPrice + Number(item.amount);
        const amountDiffMsg = '일할계산합계와 환불총액이 다릅니다.<br/>(실제 환불액은 환불총액으로 집계됨)<br/></br>';
        const msg = `
          ${amountDiff === 0 ? '' : amountDiffMsg}
          환불을 승인할까요?<br/><br/>※ 고객에게로의 서비스 제공이 즉시 중단되며 원복할 수 없습니다.
        `;
        this.confirm({
          show: true,
          msg,
          func: () => {
            this.updateSales({
              no: item.no,
              status: toStatus,
              date: this.confirmDate,
            }, msgArr[toStatus]);
          },
          btnText: '환불 승인',
          color: 'error',
        });
      } else if (toStatus === 0) {
        const msg = '환불 승인을 취소할까요?<br/><br/>※ 환불로 중단됬던 서비스는 원복되지 않습니다.';
        this.confirm({
          show: true,
          msg,
          func: () => {
            this.updateSales({
              no: item.no,
              status: toStatus,
            }, msgArr[toStatus]);
          },
          btnText: '승인 취소',
          color: 'error',
        });
      } else {
        this.alert(['error', '오류가 발생했습니다.']);
      }
    },
    updateDate(item) {
      this.confirm({
        show: true,
        msg: '영업담당자 인센티브 정산에 즉시 반영됩니다.<br/><br/>정말 변경할까요?',
        func: () => {
          this.updateSales({
            no: item.no,
            date: this.confirmDate,
          }, '변경되었습니다.');
        },
        btnText: '변경',
        color: 'primary',
      });
    },
    updateSales(obj, msg) {
      this.progress(true);
      this.$socket.emit('sales.update', obj, (resp) => {
        if (resp.result === 'success') {
          this.alert(['success', msg]);
          this.pickerInit();
          this.getSummary();
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    getPaymentsDiff(item) {
      const paymentsAmount = item.amount === null ? 0 : item.amount;
      return paymentsAmount - ((item.productPrice * item.quantity) + item.discount);
    },
    confirmDlgOpen(item, toStatus = null) {
      const func = () => {
        if (toStatus === 1) {
          this.confirmDate = this.time.moment().format('YYYY-MM-DD');
          this.serviceStartDate = this.time.moment().format('YYYY-MM-DD');
          this.serviceEndDate = this.time.moment().add(
            item.productTerm + item.productFreeTerm, 'M',
          ).format('YYYY-MM-DD');
        } else {
          this.confirmDate = this.time.makeLocalTime(item.date, 'date');
        }
        this.confirmDlg = {
          show: true,
          toStatus,
        };
        this.progress(false);
      };
      if (item.type === 0) {
        this.currentItem = item;
        if (toStatus === 1 && item.status !== 2) {
          this.$socket.emit('service.count.get', { customer: item.customer }, (resp) => {
            if (resp.result === 'success') {
              if (resp.item[0].serviceCount <= 0) {
                func();
              } else {
                this.alert(['error', '고객에게 이미 제공중인 서비스가 있어 처리할 수 없습니다.']);
              }
              this.progress(false);
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
              console.error(resp);
              this.progress(false);
            }
          });
        } else {
          func();
        }
      } else if (item.type === 1) {
        this.progress(true);
        this.$socket.emit('sales.get', { no: item.origin }, (resp) => {
          if (resp.result === 'success') {
            this.currentItem = { ...item, originSale: resp.item };
            const originStatus = this.currentItem.originSale.status;
            if (originStatus !== 1 && toStatus === 1) {
              this.confirm({
                show: true,
                msg: `기존 매출건이 승인되지 않아 처리할 수 없습니다.<br/>(기존매출번호: ${this.currentItem.origin})`,
                color: 'primary',
                noneCallback: true,
              });
              this.progress(false);
            } else {
              func();
            }
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
            this.progress(false);
          }
        });
      } else {
        this.alert(['error', '오류가 발생했습니다.']);
      }
    },
    setServiceEndDate() {
      const endDate = time.moment(this.serviceStartDate)
        .add(this.currentItem.productTerm + this.currentItem.productFreeTerm, 'M').format('YYYY-MM-DD');
      this.serviceEndDate = endDate;
    },
    getHistories(action, columnName, title, item) {
      this.progress(true);
      this.currentItem = item;
      this.$socket.emit('log.get', {
        action,
        key: String(this.currentItem.no),
      }, (resp) => {
        if (resp.result === 'success') {
          if (columnName === 'manager') {
            const staffs = [];
            this.$socket.emit('users.staffs.list.get', {
              page: 1,
              itemsPerPage: 0,
              sortBy: [],
              sortDesc: [],
              filters: [],
            }, (response) => {
              if (response.result === 'success') {
                response.items.forEach((r) => {
                  const groupName = comm.getGroupFullPath(this.groups, r.groupNo, '그룹없음 ');
                  const user = {
                    id: r.id,
                    fullName: `${groupName}${r.name}`,
                    name: r.name,
                    group: groupName,
                  };
                  staffs.push(user);
                });
                const filterData = this.makeHistories[columnName](resp.items, staffs);
                this.historyDlg.data = filterData;
                this.historyDlg.show = true;
                this.historyDlg.title = title;
                this.progress(false);
              } else {
                this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${response.code})`]);
                console.error(response);
                this.progress(false);
              }
            });
          } else {
            const filterData = this.makeHistories[columnName](resp.items, this);
            this.historyDlg.data = filterData;
            this.historyDlg.show = true;
            this.historyDlg.title = title;
            this.progress(false);
          }
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    getEstRefundAmount(date) {
      const localDate = time.makeLocalTime(date, 'date');
      const saleDate = time.moment(
        time.makeLocalTime(this.currentItem.originSale.serviceStart, 'date'),
      ).format('YYYY-MM-DD');
      const endDate = time.moment(saleDate)
        .add(this.currentItem.originSale.productTerm, 'M').format('YYYY-MM-DD');
      const serviceTerm = time.moment(endDate).diff(saleDate, 'days');
      const diffDays = time.moment(localDate).diff(saleDate, 'days') >= 0
        ? time.moment(localDate).diff(saleDate, 'days') : 0;
      const dayPrice = Math.floor(Number(this.currentItem.originSale.amount) / serviceTerm);
      const refundPrice = (Number(this.currentItem.originSale.amount) / serviceTerm)
        * (serviceTerm - diffDays);
      return {
        saleDate, // 매출일
        endDate, // 일할계산종료일
        serviceTerm, // 일할계산일수
        date: localDate, // 환불일
        diffDays, // 서비스이용일수
        remainDays: serviceTerm - diffDays > 0 ? serviceTerm - diffDays : 0, // 잔여일수
        dayPrice, // 일할계산합계(1일)
        refundPrice: refundPrice > 0 ? Math.round(refundPrice) : 0, // 일할계산합계(잔여합계)
      };
    },
    excelDownloadFunc() {
      this.progress(true);
      this.excelDownloadList = null;
      this.$socket.emit('sales.list.get', {
        ...this.options,
        ...this.sorts,
        itemsPerPage: 0,
      }, (resp) => {
        if (resp.result === 'success') {
          if (resp.items.length <= 0) {
            this.alert(['error', '다운로드할 목록이 없습니다.']);
            this.excelDownloadList = [];
            this.progress(false);
          } else {
            const typeArr = ['매출', '환불'];
            const statusArr = ['미승인', '승인', '가결제'];
            const productTypeArr = ['리딩', '코인'];
            const list = resp.items.reduce((arr, r) => {
              const row = {};
              row['매출번호'] = r.no;
              row['종류'] = typeArr[r.type];
              row['상태'] = statusArr[r.status];
              row['승인일'] = r.status === 1 ? time.makeLocalTime(r.date, 'date') : '-';
              row['고객정보'] = `${r.customerName}(${r.customerPhone.slice(-4)})`;
              row['상품종류'] = productTypeArr[r.productType];
              row['상품'] = r.productName;
              row['수량'] = r.productType === 0 ? '-' : r.quantity;
              row['결제차액'] = r.type === 0 ? this.getPaymentsDiff(r) : '-';
              row['매출합계'] = r.amount;
              row['영업담당자'] = `${comm.getGroupFullPath(this.groups, r.managerGroupNo, '그룹없음 ')}${r.managerName}`;
              row['영업그룹'] = comm.getGroupFullPath(this.groups, r.group, '그룹없음 ');
              arr.push(row);
              return arr;
            }, []);
            this.excelDownloadList = list;
            comm.makeExcelFile(this.excelDownloadList, `매출목록[${time.moment().format('YYYY-MM-DD HH_mm_ss')}].xlsx`);
            this.progress(false);
          }
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
          this.excelDownloadList = [];
        }
      });
    },
    checkFuncPermission(funcName) {
      try {
        const userPermission = { ...this.userInfo.permission.sales.menu.sales.func };
        let result = false;
        if (
          userPermission[funcName] !== undefined
          && userPermission[funcName].bool === true
        ) result = true;
        return result;
      } catch (error) {
        console.error('checkFuncPermission error');
        return false;
      }
    },
    getProductRooms(no) {
      this.currentRooms = null;
      this.$socket.emit('rooms.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [true],
        filters: [{
          condition: 'eq',
          column: 'disabled',
          value: 0,
        }, {
          where: 'and',
          condition: 'eq',
          column: 'productNo',
          value: no,
        }],
      }, (resp) => {
        if (resp.result === 'success') {
          this.currentRooms = resp.items;
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          this.getSummary = debounce(this.getSummary, 300);
          this.getDefaultData();
        }
      },
    },
    options: {
      deep: true,
      handler() {
        if (this.userInfo != null && this.totalItems >= 0) {
          this.getSummary();
        }
      },
    },
    dateFilter: {
      deep: true,
      handler(val) {
        if (this.userInfo != null && val.endPicker === false && val.startPicker === false) {
          this.options.filters[0].filters[0].filters[0].value = time.makeUTCDate(val.startDate);
          this.options.filters[0].filters[0].filters[1].value = time.makeUTCDate(
            `${val.endDate} 23:59:29`,
          );
        }
      },
    },
    radioFilters: {
      deep: true,
      handler(val) {
        if (this.userInfo != null) {
          const type = val.type.value === '전체' ? '' : val.type.value;
          const productType = val.productType.value === '전체' ? '' : val.productType.value;
          const status = val.status.value === '전체' ? '' : val.status.value;
          const method = val.method.value === '전체' ? '' : val.method.value;
          this.options.filters[1].value = type;
          this.options.filters[2].value = productType;
          this.options.filters[3].value = status;
          this.options.filters[4].value = method;
        }
      },
    },
    selectFilters: {
      deep: true,
      handler(val) {
        if (this.userInfo != null) {
          if (val.groupNo.value === '전체') {
            // groupNo = null 을 조회하기위해 검색하지않는 컬럼을 기본지정함
            this.options.filters[5].column = 'discount';
            this.options.filters[5].condition = 'inc';
            this.options.filters[5].value = '';
          } else {
            this.options.filters[5].column = 'groupNo';
            this.options.filters[5].condition = 'eq';
            this.options.filters[5].value = val.groupNo.value;
          }
        }
      },
    },
    search: {
      handler(val) {
        if (this.userInfo != null) {
          this.options.filters[6].filters[0].value = val;
          this.options.filters[6].filters[1].value = val;
          this.options.filters[6].filters[2].value = val;
          this.options.filters[6].filters[3].value = val;
          this.options.filters[6].filters[4].value = val;
        }
      },
    },
    sorts: {
      deep: true,
      handler() {
        this.getSummary();
      },
    },
    saleDlg: {
      deep: true,
      handler(val) {
        if (val.show === false) {
          this.getSummary();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.total {
  justify-content: flex-end;
  padding-top: 30px;
  p {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    & + p {
      margin-left: 20px;
    }
  }
}
.date-picker-top {
  background-color: #fff;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  padding: 15px;
  .tit {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 500;
    color: #262626;
    display: flex;
    align-items: center;
    i {
      margin-left: 2px;
    }
  }
}
.date-picker-content {
  border-radius: 0;
  .total-span {
    font-size: 0.85rem;
    font-weight: 500;
  }
}
.options {
  font-size: 0.9rem;
  padding: 0px 20px;
  min-height: 34px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
}
.gray--text {
  color: #ccc;
}
.detail-view-text {
  font-size: 0.9rem;
  margin-top: 18px;
  color: #888;
  &.true--text {
    color: #262626;
  }
}
.confirm-picker-body {
  background-color: #fff;
  padding: 10px;
  padding-top: 0px;
  .section {
    position: relative;
    padding: 15px;
    display: flex;
    align-items: center;
    color: #333;
    justify-content: space-between;
    & + .section,
    & + .section-wrap {
      border-top: 1px dashed #ccc;
    }
    .tit {
      font-size: 0.9rem;
      font-weight: 500;
      margin: 0;
    }
    .sub {
      font-weight: 600;
      font-size: 0.9rem;
      display: flex;
      align-items: center;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.search-picker {
  max-width: 800px;
  .v-list {
    max-height: 300px;
    overflow: auto;
  }
}
</style>
